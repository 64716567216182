import { createGlobalStyle } from "styled-components"

const ResetCSS = createGlobalStyle`
  html {
    font-size: 16px;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;
  }



  // body {
  //   animation: fadeinbody 1s forwards;
  // }
  
  @keyframes fadeinbody {
    0% {opacity: 0}
    100% {opacity: 1}
  }



  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: 0;
    font-family: Helvetica, Arial, Sans-Serif;
  }

  body {
    font-family: Helvetica, Arial, Sans-Serif;
    margin: 0;
    padding: 0;
    font-size: 16px;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  p{
    line-height: 2;
    margin: 0 0 1.7em 0;
  }
  
  input, textarea, select, button{
    font-family: Helvetica, Arial, Sans-Serif;
  ;}

  ul,ol {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    box-shadow: none;
  }

  a:hover {
    text-decoration: none;
  }

  blockquote{
    font-family: Helvetica, Arial, Sans-Serif;
    font-weight: 500;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-"] {
    background: #f5f5f5;
  }

  pre[class*="language-"] {
    padding: 1.5em;
    margin: 2em 0;
    overflow: auto;
  }



  .nav-sticky{
    .header {
      box-shadow: 0 0 16px rgba(0,0,0,.08);
      .navbar{
        @media (min-width: 1400px) {
          padding-top: 25px;
          padding-bottom: 25px;
        }
        @media (min-width: 1200px) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }
    
  }

`
export default ResetCSS
